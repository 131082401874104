import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import routerM from '@/router/routerM'
import routerPC from '@/router/routerPC'
import { JUDGE_CLIENT } from '@/utils/common'

const otherRoutes = JUDGE_CLIENT() === 'pc' ? routerPC : routerM
const redirectSite = process.env.VUE_APP_PATH || ''

// 重定向路由
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: redirectSite
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...routes, ...otherRoutes],
    scrollBehavior() {
        // 始终滚动到顶部
        return { top: 0 }
    }
})

router.beforeEach(to => {
    // 设置页面标题
    if (to.meta.title) {
        const appDom = document.querySelector('#app') as HTMLElement
        if (appDom && appDom.style.display !== 'none') {
            document.title = to.meta.title as string
        }
    }

    // 更换网站图标
    if (to.meta.icon) {
        const iconLink = `/icon/${to.meta.icon}.ico`
        let $favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement
        if ($favicon !== null) {
            $favicon.href = iconLink
        } else {
            $favicon = document.createElement('link')
            $favicon.rel = 'icon'
            $favicon.href = iconLink
            document.head.appendChild($favicon)
        }
    }
})

export default router
