export default [
    /* ------------------------------------------------------ */
    // 桃鹿用户端下载
    {
        path: '/deerC',
        name: 'deerC',
        component: () => import('@/views/pc/deer/deerC.vue'),
        meta: {
            title: '桃鹿',
            icon: 'deerC'
        }
    },
    // 桃鹿主播端下载
    {
        path: '/deerA',
        name: 'deerA',
        component: () => import('@/views/pc/deer/deerA.vue'),
        meta: {
            title: '桃鹿助手',
            icon: 'deerA'
        }
    },
    /* ------------------------------------------------------ */
    // 网址安全栅栏导航
    {
        path: '/nav',
        name: 'nav',
        component: () => import('@/views/pc/nav/index.vue'),
        meta: {
            title: '深圳涵文信息技术有限公司'
        }
    }
]
