export default [
    // 充值页（通用）
    {
        path: '/pay',
        name: 'pay',
        component: () => import('@/components/mobile/Pay.vue'),
        meta: {
            title: '充值'
        }
    },
    /* ------------------------------------------------------ */
    // ios企业下载教程
    {
        path: '/iosEnterprise',
        name: 'iosEnterprise',
        component: () => import('@/views/mobile/rules/iosEnterprise.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // ios桃心理隐藏版进入教程
    {
        path: '/iosTxl',
        name: 'iosTxl',
        component: () => import('@/views/mobile/rules/iosTxl.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // 猜个谜语教程
    {
        path: '/guessRiddle',
        name: 'guessRiddle',
        component: () => import('@/views/mobile/rules/guessRiddle.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // android桃心理隐藏版进入教程
    {
        path: '/androidTxl',
        name: 'androidTxl',
        component: () => import('@/views/mobile/rules/androidTxl.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // android计算机隐藏版进如教程
    {
        path: '/androidComputer',
        name: 'androidComputer',
        component: () => import('@/views/mobile/rules/androidComputer.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // 一掌金隐藏教程
    {
        path: '/onePalmGold',
        name: 'onePalmGold',
        component: () => import('@/views/mobile/rules/onePalmGold.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },
    // 万物皆可拼教程
    {
        path: '/jigsaw',
        name: 'jigsaw',
        component: () => import('@/views/mobile/rules/jigsaw.vue'),
        meta: {
            title: '圈内平台',
            icon: 'deerC'
        }
    },

    /* ------------------------------------------------------ */
    // 桃鹿用户端下载
    {
        path: '/deerC',
        name: 'deerC',
        component: () => import('@/views/mobile/deer/deerC.vue'),
        meta: {
            title: '桃鹿直播',
            icon: 'deerC'
        }
    },
    // 桃鹿主播端下载
    {
        path: '/deerA',
        name: 'deerA',
        component: () => import('@/views/mobile/deer/deerA.vue'),
        meta: {
            title: '桃鹿直播助手',
            icon: 'deerA'
        }
    },
    // 桃鹿主播端-常见问题
    {
        path: '/deerA/FAQ',
        name: 'deerA-FAQ',
        component: () => import('@/views/mobile/deer/rules/FAQ.vue'),
        meta: {
            title: '常见问题',
            icon: 'deerA'
        }
    },
    /* ------------------------------------------------------ */
    // 网址安全栅栏导航
    {
        path: '/nav',
        name: 'nav',
        component: () => import('@/views/mobile/nav/index.vue'),
        meta: {
            title: '深圳涵文信息技术有限公司'
        }
    }
]
